import { useEffect } from 'react'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from 'react-hot-toast'

import AddToWorkOrderButton from 'src/components/AddToWorkOrderButton/AddToWorkOrderButton'
import DueListTable from 'src/components/DueListTable/DueListTableV2'
import SearchComponent from 'src/components/SearchComponent/SearchComponent'
import useCreateComplianceRecords from 'src/hooks/requests/useCreateComplianceRecords'
import useMaintenanceItemTable from 'src/hooks/useMaintenanceItemTable'
import useGetDueListSelectedAircraft from 'src/hooks/useGetDueListSelectedAircraft'

import ComplianceButton from './ComplianceButton'
import { MaintenanceItemFilters } from 'types/graphql'
import ApplyTimesButton from 'src/components/common/ApplyTimesButton'

type MaintenanceItemListProps = {
  orgName: string
  title: string
  variant: 'duelist' | 'maintenanceItems'
  showSummaryCards?: boolean
  queryVariables: MaintenanceItemFilters
}

const MaintenanceItemList = ({
  orgName,
  title,
  variant,
  showSummaryCards = true,
  queryVariables,
}: MaintenanceItemListProps) => {
  const {
    aircraftFilter,
    selectedItemIds,
    selectedInProgressItems,
    setFilterDataBy,
    resetFilters,
    unselectAll,
  } = useMaintenanceItemTable(variant)

  // const { mtxItemStats, loadStats } = useMaintenanceItemStats()

  useEffect(() => {
    // Fire once to clean due list selected state on mount.
    // e.g. user that selected items in due list page does not reset when navigating to this page.
    unselectAll()
  }, [])

  const selectedAircraft = useGetDueListSelectedAircraft(variant)

  const {
    createRecords,
    ledgerData,
    error,
    loading: ledgerLoading,
  } = useCreateComplianceRecords()

  React.useEffect(() => {
    // after creating the records, navigate to the bulk compliance page
    if (!ledgerLoading && ledgerData) {
      if (
        error?.message &&
        error?.message !== 'COMPLIANCE_LEDGER_ALREADY_EXISTS'
      ) {
        toast.error(error.message)
        return
      }
      navigate(
        routes.bulkCompliance({
          orgName: orgName,
          ledgerId: ledgerData.createComplianceLedger.id,
        })
      )
    }
  }, [ledgerData, error, ledgerLoading, selectedAircraft, orgName])

  useEffect(() => {
    // resets filters on unmount
    return () => {
      resetFilters()
    }
  }, [])

  const onAddCompliance = async () => {
    // Only add items that are not in progress
    const filteredItemIds = selectedItemIds.filter(
      (id) => !selectedInProgressItems[id]
    )
    createRecords(selectedAircraft, filteredItemIds)
  }

  const applyTimesButton = (
    <ApplyTimesButton
      aircraftId={selectedAircraft}
      loading={true}
      disablePick={true}
    />
  )

  const complianceButton = (
    <ComplianceButton
      selectedAircraft={selectedAircraft}
      selectedItemIds={selectedItemIds}
      onAddCompliance={onAddCompliance}
      selectedInProgressItems={selectedInProgressItems}
    />
  )

  const addToWorkOrderButton = (
    <AddToWorkOrderButton
      tableVariant={variant}
      onCompleted={({ workOrder }) => {
        navigate(routes.workOrder({ orgName: orgName, id: workOrder.id }))
      }}
    />
  )

  return (
    <div className="flex h-full flex-col">
      <SearchComponent variant={variant} orgName={orgName} />
      <div className="flex h-full flex-col p-4">
        <DueListTable
          selectedAircraft={selectedAircraft}
          variant={variant}
          queryVariables={queryVariables}
          enableRowVirtualization={true}
          actions={[applyTimesButton, complianceButton, addToWorkOrderButton]}
        />
      </div>
    </div>
  )
}

export default MaintenanceItemList
