import React, { useState } from 'react'
import { Button, Typography, Chip } from '@mui/material'
import { useMutation } from '@redwoodjs/web'
import SelectedTasksList from '../components/SelectedTasksList'
import useCreateComplianceRecords from 'src/hooks/requests/useCreateComplianceRecords'
import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'
import useConversation from '../useConversation'
import ConversationDisplay from '../ConversationDisplay'
import AiLoadingIndicator from '../AiLoadingIndicator'
import useAppLayoutState from 'src/hooks/useAppLayoutSlice'
import { fakeData } from './fakeData'

const PROCESS_LOGBOOK_PDF = gql`
  mutation ProcessLogbookPdf($aircraftId: String!, $file: File!) {
    processLogbookPdf(aircraftId: $aircraftId, file: $file) {
      message
      aircraft {
        ComplianceLedger {
          id
          complianceActivity {
            id
            maintenanceItemId
          }
        }
      }
      maintenanceItems {
        aircraft {
          ComplianceLedger {
            id
          }
        }
        id
      }
    }
  }
`

interface UploadButtonProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  hide: boolean
}

const UploadButton: React.FC<UploadButtonProps> = ({ onFileChange, hide }) => {
  if (hide) return null
  return (
    <div>
      <input
        type="file"
        onChange={onFileChange}
        style={{ display: 'none' }}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button variant="contained" component="span">
          Upload Logbook Entry
        </Button>
      </label>
    </div>
  )
}

interface ComplianceByLogbookProps {
  aircraftId: string
}

const loadingSteps = [
  'Scanning logbook entry',
  'Extracting textual information',
  'Decoding maintenance codes',
  'Interpreting maintenance tasks',
  'Matching tasks with Wing Work database',
  'Finalizing results',
]

const ComplianceByLogbook = ({ aircraftId }: ComplianceByLogbookProps) => {
  console.log('AIRCRAFT_ID', aircraftId)
  const orgName = useOrgName()
  const [isProcessing, setIsProcessing] = useState(false)
  const [showConversation, setShowConversation] = useState(true)
  const [processingComplete, setProcessingComplete] = useState(false)
  const [detectedMaintenanceItems, setDetectedMaintenanceItems] = useState<
    string[]
  >(fakeData.maintenanceItems.map((item) => item.id))
  const { setAiAssistantDrawerOpen } = useAppLayoutState()
  const { createRecords } = useCreateComplianceRecords({
    getOrCreate: true,
  })

  const [processLogbookPdf, { data: processedLogbookData }] =
    useMutation(PROCESS_LOGBOOK_PDF)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)
      addMessage("You've selected the following file:")
      addMessage(
        <div className="flex flex-wrap gap-1">
          {files.map((file, index) => (
            <Chip key={index} size="small" label={file.name} />
          ))}
        </div>
      )
      addMessage('Processing your file...')
      setIsProcessing(true)
      handleSubmit(files[0])
    }
  }

  const { conversation, addMessage } = useConversation([
    'Hello! Please upload a logbook entry to add compliance.',
  ])

  const handleSubmit = async (file: File) => {
    try {
      //#region Process Logbook PDF
      // const result = await processLogbookPdf({
      //   variables: {
      //     aircraftId,
      //     file: file
      //   }
      // });
      // setIsProcessing(false);
      // setProcessingComplete(true);
      // console.log(result)
      // setDetectedMaintenanceItems(result.data.processLogbookPdf.maintenanceItems.map((item: any) => item.id))
      // setShowConversation(false)
      //#endregion

      // #region Simulate processing time
      await new Promise((resolve) => setTimeout(resolve, 15000))

      // Fake result data
      const fakeResult = {
        data: {
          processLogbookPdf: {
            maintenanceItems: detectedMaintenanceItems.map((id) => ({ id })),
          },
        },
      }

      setIsProcessing(false)
      setProcessingComplete(true)
      setDetectedMaintenanceItems(
        fakeResult.data.processLogbookPdf.maintenanceItems.map(
          (item: any) => item.id
        )
      )
      setShowConversation(false)
      //#endregion
    } catch (error) {
      setIsProcessing(false)
      addMessage(
        'An error occurred while processing the file. Please try again.'
      )
      console.error('Error processing file:', error)
    }
  }

  const handleConfirmTasks = async () => {
    console.log('CONFIRMING TASKS', detectedMaintenanceItems)
    setShowConversation(true)
    addMessage(
      <div className="flex flex-col gap-1">
        <Typography>
          Maintenance items were added to the in progress compliance ledger
        </Typography>
        <Button
          onClick={() => {
            setAiAssistantDrawerOpen(false)
            navigate(
              routes.bulkCompliance({
                orgName: orgName,
                ledgerId: '5d88db91-2c8a-48bd-abe3-66d31869573b',
              })
            )
          }}
        >
          Go to Compliance
        </Button>
      </div>
    )
    return
    // #region Create Compliance Records
    // const existingComplianceActivities = processedLogbookData.processLogbookPdf.aircraft.ComplianceLedger[0]?.complianceActivity || [];
    // const existingMaintenanceItemIds = existingComplianceActivities.map(activity => activity.maintenanceItemId);

    // const filteredMaintenanceItems = detectedMaintenanceItems.filter(
    //   itemId => !existingMaintenanceItemIds.includes(itemId)
    // );

    // debugger

    // const { ledgerData, error } = await createRecords(aircraftId, filteredMaintenanceItems)
    // if (ledgerData) {
    //   if (
    //     error?.message &&
    //     error?.message !== 'COMPLIANCE_LEDGER_ALREADY_EXISTS'
    //   ) {
    //     toast.error(error.message)
    //     return
    //   }
    //   setShowConversation(true)
    //   if (processedLogbookData.processLogbookPdf.aircraft.ComplianceLedger.length > 0) {
    //     addMessage(
    //       <div className="flex flex-col gap-1">
    //         <Typography>Maintenance items were added to the in progress compliance ledger</Typography>
    //         <Button onClick={() => {
    //           setAiAssistantDrawerOpen(false)
    //           navigate(routes.bulkCompliance({
    //             orgName: orgName,
    //             ledgerId: ledgerData.id,
    //           }))
    //         }}
    //         >
    //           Go to Compliance
    //         </Button>
    //       </div>
    //     )
    //   }
    //   else {
    //     addMessage(
    //       <div>
    //         <Typography>
    //           A compliance ledger is created with the maintenance items detected.
    //         </Typography>
    //         <Button onClick={() => {
    //           setAiAssistantDrawerOpen(false)
    //           navigate(routes.bulkCompliance({
    //             orgName: orgName,
    //             ledgerId: ledgerData.id,
    //           }))
    //         }}
    //         >
    //           Go to Compliance
    //         </Button>
    //       </div>
    //     )
    //   }
    // }
    // #endregion
  }

  if (!aircraftId) {
    return <Typography>Please select an aircraft first</Typography>
  }

  return (
    <div className="flex flex-col gap-2 p-2">
      {showConversation && (
        <>
          <ConversationDisplay conversation={conversation} />
          <UploadButton
            key="upload-button"
            onFileChange={handleFileChange}
            hide={isProcessing || processingComplete}
          />
          {isProcessing && <AiLoadingIndicator steps={loadingSteps} />}
        </>
      )}
      {!showConversation &&
        processingComplete &&
        detectedMaintenanceItems.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <Typography variant="h6">
                Please review the maintenance items detected:
              </Typography>
              <Button onClick={handleConfirmTasks}>Confirm Tasks</Button>
            </div>
            <SelectedTasksList
              aircraftIds={[aircraftId]}
              selectedTasks={detectedMaintenanceItems}
              setSelectedTasks={setDetectedMaintenanceItems}
            />
          </div>
        )}
    </div>
  )
}

export default ComplianceByLogbook
